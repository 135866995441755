// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-builder-tsx": () => import("./../../../src/components/PageBuilder.tsx" /* webpackChunkName: "component---src-components-page-builder-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/BlogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-blog-tags-tsx": () => import("./../../../src/templates/BlogTags.tsx" /* webpackChunkName: "component---src-templates-blog-tags-tsx" */),
  "component---src-templates-business-use-case-categories-tsx": () => import("./../../../src/templates/BusinessUseCaseCategories.tsx" /* webpackChunkName: "component---src-templates-business-use-case-categories-tsx" */),
  "component---src-templates-business-use-case-list-template-tsx": () => import("./../../../src/templates/BusinessUseCaseListTemplate.tsx" /* webpackChunkName: "component---src-templates-business-use-case-list-template-tsx" */),
  "component---src-templates-case-studies-list-tsx": () => import("./../../../src/templates/CaseStudiesList.tsx" /* webpackChunkName: "component---src-templates-case-studies-list-tsx" */),
  "component---src-templates-case-study-categories-tsx": () => import("./../../../src/templates/CaseStudyCategories.tsx" /* webpackChunkName: "component---src-templates-case-study-categories-tsx" */),
  "component---src-templates-ebooks-list-tsx": () => import("./../../../src/templates/EbooksList.tsx" /* webpackChunkName: "component---src-templates-ebooks-list-tsx" */),
  "component---src-templates-faq-fa-qs-tsx": () => import("./../../../src/templates/FAQ/FAQs.tsx" /* webpackChunkName: "component---src-templates-faq-fa-qs-tsx" */),
  "component---src-templates-guide-guide-tsx": () => import("./../../../src/templates/Guide/Guide.tsx" /* webpackChunkName: "component---src-templates-guide-guide-tsx" */),
  "component---src-templates-interview-list-tsx": () => import("./../../../src/templates/InterviewList.tsx" /* webpackChunkName: "component---src-templates-interview-list-tsx" */),
  "component---src-templates-our-clients-speak-list-tsx": () => import("./../../../src/templates/OurClientsSpeakList.tsx" /* webpackChunkName: "component---src-templates-our-clients-speak-list-tsx" */)
}

